.loginSection {
    background-image: url("../../Assets/Images/background/login.png");
    background-color: #1F2937;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    text-align: center;
}

.logo img {
    margin-bottom: 40px;
    width: auto;
}

.loginForm {
    background-color: #fff;
    padding: 40px 32px;
    width: 460px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.loginForm h6 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;

}

.loginInput label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: block;
    margin-bottom: 6px;
    color: #344054;
}

.loginInput {
    position: relative;
    margin-bottom: 15px;
}

.loginInput input {
    width: 100%;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px 12px 10px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
}

.loginInput .form-control:focus {
    color: none !important;
    background-color: none !important;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.loginInput span {
    position: absolute;
    right: 13px;
    bottom: 13px;
    cursor: pointer;
}

.loginBtn button {
    margin-top: 38px;
    background: #6366F1;
    width: 100%;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: #fff;
    padding: 18px 24px 18px 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.agreement {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.agreement .tableCheckBox {
    width: 25px;
}

.agreement span {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
    cursor: pointer;
}

.tableCheckBox {
    position: relative;
    cursor: pointer;
    /*
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;
    margin-bottom: 12px;
    margin-right: 30px; */
}

.tableCheckBox input {
    opacity: 0;
    /* position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; */
}

.tableCheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #a8aeb9;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.tableCheckBox input:checked~.checkmark {
    background-color: #ffffff;
}

.tableCheckBox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.tableCheckBox input:checked~.checkmark:after {
    display: block;
}

.tableCheckBox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #6366F1;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}