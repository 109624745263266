.mainContent {
    background: #F0F4F6;
    /* min-height: 100vh; */
    padding: 32px 32px 33px 32px;
    width: 100%;
    height: calc(100vh - 72px);
    overflow-y: auto;
}

.memberBox {
    background-color: #fff;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.topLine {
    padding: 0px 25px;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    flex-wrap: wrap;
}

.topLine h6 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    margin-bottom: 2px;
}
.topLine h6 svg{
    color: rgba(102, 112, 133, 1);
margin-right: 12px;
}

.topLine button {
    background: #6366F1;
    padding: 12px 16px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberSearch {
    display: flex;
    flex-wrap: wrap;
}

.memberSearch input {
    border: none;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px 30px 10px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    background: #F4F7F8;
}

.memberSearch .form-control:focus {
    color: none !important;
    background: #F4F7F8;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.searchInput {
    position: relative;
    margin-right: 12px;
}

.searchInput input {
    min-width: 360px;
}

.searchInput svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
}

.memberList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 224px);
}

.memberList .table-responsive {
    width: 100%;
}

.memberList table tbody tr td {
    vertical-align: middle;
}

.memberList table tbody tr td:nth-child(2) {
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
}

.memberList table tbody tr td:nth-child(3),
.memberList table tbody tr td:nth-child(4){
    color: #475467;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
}

.memberList table tbody tr td img {
    width: 40px;
    height: 40px;
    margin-right: 6px;
}

/* .memberList table thead tr th .tableCheckBox,
.memberList table tbody tr td .tableCheckBox {
    width: 12px;
} */

.tableAction .view {
    border: 1px solid #6366F1;
    color: #6366F1;
    width: 40px;
    height: 40px;
    padding: 0px;
}

.tableAction .view:hover {
    background-color: #6366F1;
    color: #ffffff;
}

.tableAction .edit {
    color: #667085;
    width: 40px;
    height: 40px;
    padding: 0px;
    text-align: center;
}
.tableAction .edit img{
    width: 20px;
    height: 20px;
}

.tableAction .edit:hover {
    color: #6366F1;
}
.tableAction .assign{
    width: 79px;
    height: 40px;
    border: 1px solid #6366F1;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #475467;    
}

.tableAction .assign:hover {
    color: #ffffff;
    background-color: #6366F1;
}

.memberList table thead tr,
.memberList table thead tr th {
    background-color: #F4F7F8;
}

.memberList table thead tr th {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #475467;
    height: 56px;
    vertical-align: middle;
    white-space: nowrap;
}

.memberList table thead tr th:first-child,
.memberList table tbody tr td:first-child {
    width: 68px;
    vertical-align: middle;
    text-align: center;
}

.memberList table thead tr th:nth-child(3) {
    width: 284px;
    text-align: left;
}

.memberList table thead tr th:nth-child(4) {
    width: 206px;
    text-align: left;
}

.memberList table thead tr th:nth-child(5) {
    width: 206px;
    text-align: left;
}

.memberList table thead tr th:nth-child(6) {
    width: 116px;
    text-align: center;
}

.memberList table tbody tr td:nth-child(6) {
    width: 116px;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* pagination */
.paginationBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 20px;
}

.tableNumber {
    display: flex;
    align-items: center;
}

.tableNumber .paginationDropdown {
    margin-right: 10px;
}

.paginationDropdown button {
    width: 80px;
    height: 40px;
    text-align: left;
    background-color: #fff;
    color: #98A2B3;
    font-weight: 500;
    border: 1px solid #D0D5DD;
    padding: 0px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.paginationDropdown button:hover {
    background-color: #fff;
    color: #98A2B3;
    border: 1px solid #D0D5DD;
}

.paginationDropdown .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0px;
    vertical-align: none !important;
    content: "";
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.paginationDropdown .dropdown-toggle:before {
    position: absolute;
    right: 12px;
    top: 16px;
    display: inline-flex;
    line-height: 0;
    content: url("../../Assets/Images/icon/downIcon.png");
    transform-origin: center;
    transition: transform .35s ease;
    -webkit-transition: transform .35s ease;
    -moz-transition: transform .35s ease;
    -ms-transition: transform .35s ease;
    -o-transition: transform .35s ease;
}

.paginationNumber button {
    width: 40px;
    height: 40px;
    border: 1px solid #D0D5DD;
    color: #344054;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    vertical-align: middle;
    background-color: #fff;
}

.paginationNumber .disable {
    pointer-events: none;
    background-color: rgb(228, 228, 228);
}

.paginationNumber button:nth-child(1),
.paginationNumber button:last-child {
    width: 120px;
}

.paginationNumber button:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.paginationNumber button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.paginationNumber button:first-child svg {
    font-size: 16px;
    padding-right: 8px;
}

.paginationNumber button:last-child svg {
    font-size: 16px;
    padding-left: 8px;
}


.tableNumber p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
    margin-bottom: 0px;
}

@media (max-width: 778px) {
    .searchInput input {
        min-width: 100%;
    }
    .mainContent{
        height: auto !important;
        padding: 32px 10px 57px 10px;
    }
}